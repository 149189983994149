import { useCallback, useContext, useEffect, useMemo } from 'react';

import {
  SlideViewerContextProvider,
  StyledSlideViewer,
  usePrismTheme,
} from '@ftrprf/tailwind-components';

import useTeacherCreateContentFeedback from 'hooks/api/plannerService/teacher/mutations/useTeacherCreateContentFeedback';
import useSteams from 'hooks/useSteams';
import useTitle from 'hooks/useTitle';

import { UserContext } from 'providers/UserProvider';
import contentTypes from 'utils/constants/contentTypes';
import { generateScratchExercisePath } from './utils/helpers';

export function SlideViewer({
  generateCurrentLessonPath,
  generateLessonPath,
  isCodefever = false,
  isLoadingLesson,
  isLoadingSlides,
  isSubmittingAnswers,
  lesson,
  lessonSessionId,
  onChangeSlide,
  onChangeViewMode,
  onClose,
  onSubmitQuestionAnswers,
  // nowhere used
  refetchAnswers,
  selectedPeriodIsNotActivePeriod,
  setViewMode,
  slideId,
  slides: rawSlides,
  studioId,
  submittedQuestionAnswers,
  userGroupInfo,
  viewMode,
}) {
  const user = useContext(UserContext);
  const { id, organization, settings } = user;
  const { destroyVm, groupId, initVm, isSteamsAdult, sendPageNumber } =
    useSteams(studioId);
  usePrismTheme(settings?.slideViewer?.codeBlocksTheme);
  // STEAMS stuff
  useEffect(() => {
    initVm();

    return () => {
      destroyVm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SteamsTab = useCallback(
    () => (
      <div className="overflow-hidden flex flex-col flex-grow">
        <h2 className="mt-4 px-4 mb-1 text-2xl font-bold">Steams</h2>
        <div className="overflow-y-auto flex-grow relative">
          <iframe
            allow="camera;microphone"
            frameBorder="0"
            src={`https://steams.cs.kuleuven.be/dashboard/widgets/${groupId}/${id}${
              isSteamsAdult ? '&cps2' : ''
            }`}
            style={{
              height: '100%',
              width: '100%',
            }}
            title="Steams widget"
          />
        </div>
      </div>
    ),
    [groupId, id, isSteamsAdult],
  );
  // END Steams Stuff

  useTitle(
    lesson?.title ||
      rawSlides?.filter((slide) => slide.id === slideId)[0]?.title,
  );
  const { isStudent } = user;

  const slides = useMemo(
    () =>
      rawSlides?.map((slide) => {
        // transform questions to question
        // we agreed to support multiple questions on one slide in the future,
        // but for now we'll only allow one question.
        if (!slide.questions) {
          return slide;
        }

        return {
          ...slide,
          question: slide.questions?.[0],
        };
      }),
    [rawSlides],
  );

  const slideIndex = useMemo(
    () =>
      slides
        ? slides.find((item, index) => {
            if (item.id === slideId) return index;
            return 0;
          })
        : 0,
    [slideId, slides],
  );

  const { isLoading: saveLoading, mutate: createContentFeedback } =
    useTeacherCreateContentFeedback();

  const isCk5 = lesson ? lesson.editorVersion === 5 : true;

  return (
    <SlideViewerContextProvider
      canOverrideAnswers={contentTypes[lesson?.type] === contentTypes.EXAM}
      generateCurrentLessonPath={generateCurrentLessonPath}
      generateLessonPath={generateLessonPath}
      generateScratchExercisePath={({ versionId }) =>
        generateScratchExercisePath({
          sessionId: lessonSessionId,
          studioId,
          versionId,
        })
      }
      hasMultiScreenMode={!isStudent}
      isCk5={isCk5}
      isCodefever={isCodefever}
      isSubmittingAnswers={isSubmittingAnswers}
      lesson={lesson}
      lessonSessionId={lessonSessionId}
      onChangeSlide={onChangeSlide}
      onChangeViewMode={onChangeViewMode}
      onSubmitQuestionAnswers={onSubmitQuestionAnswers}
      refetchAnswers={refetchAnswers}
      selectedPeriodIsNotActivePeriod={selectedPeriodIsNotActivePeriod}
      sendPageNumber={!!groupId && sendPageNumber}
      setViewMode={setViewMode}
      slideId={slideId}
      slideIndex={slideIndex}
      slides={slides}
      studioId={studioId}
      submittedQuestionAnswers={submittedQuestionAnswers}
      user={user}
      userDoesNotHaveSteams={organization?.steams === false}
      userGroupInfo={userGroupInfo}
      useTeacherCreateContentFeedback={{
        createContentFeedback,
        saveLoading,
      }}
      viewMode={viewMode}
    >
      <StyledSlideViewer
        isLoading={isLoadingSlides || isLoadingLesson}
        onClose={onClose}
        showTeacherInfoTab={!isStudent}
        showViewModes={!isStudent}
        steamsTab={!!groupId && SteamsTab}
      />
    </SlideViewerContextProvider>
  );
}
