import { useCallback, useContext, useEffect, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import useLessonContent from 'hooks/api/slidesService/useLessonContent';
import useLessonSlides from 'hooks/api/slidesService/useLessonSlides';

import { SLIDE_VIEWER_SIDEBAR_OPEN } from 'utils/constants/localStorage';
import URLS from 'utils/constants/urls';
import viewModes from 'utils/constants/viewModes';

import { UserContext } from 'providers/UserProvider';
import { PeriodsContext } from 'providers/PeriodsProvider';
import SlideViewerError from './partials/SlideViewerError';
import { SlideViewer } from './SlideViewer';
import {
  determineTargetSlideId,
  generateLessonPath,
  viewModeToLowerCase,
  viewModeToUpperCase,
} from './utils/helpers';

export function HackRoomLessonViewerContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedPeriodIsNotActivePeriod } = useContext(PeriodsContext);
  const { organization } = useContext(UserContext);
  const { isCodefever } = organization;

  const { slideId: urlSlideId, studioId, viewMode: urlViewMode } = useParams();

  const [viewMode, setViewMode] = useState(viewModeToUpperCase(urlViewMode));
  const [slideId, setSlideId] = useState(urlSlideId);

  const { data: slides, isLoading: isLoadingSlides } = useLessonSlides({
    studioId,
    viewMode: viewMode ?? viewModes.CLASSICAL,
  });

  const { data: lesson, isLoading: isLoadingLesson } =
    useLessonContent(studioId);

  useEffect(() => {
    if (slides && slides.length !== 0) {
      const targetSlideId = determineTargetSlideId(urlSlideId, slideId, slides);

      // Generate the path based on the current state
      const path = generatePath(URLS.SLIDEVIEWER_HACKROOM_LESSON, {
        slideId: targetSlideId,
        studioId,
        viewMode: viewModeToLowerCase(viewMode),
      });

      /**
       Compare the generated path with the current location pathname
       Navigate only if the paths are different
       */
      if (path !== location.pathname) {
        navigate(path, { replace: true });
        setSlideId(targetSlideId);
      }
    }
  }, [
    location.pathname,
    navigate,
    slideId,
    slides,
    studioId,
    urlSlideId,
    viewMode,
  ]);

  useEffect(() => {
    if (urlSlideId !== slideId) {
      setSlideId(urlSlideId);
    }
  }, [slideId, urlSlideId]);

  const onChangeViewMode = useCallback(
    (newViewMode) => {
      if (newViewMode && newViewMode !== viewMode) {
        setViewMode(newViewMode);
      }
    },
    [viewMode],
  );

  const onChangeSlide = useCallback(
    (newSlideId) => {
      if (newSlideId && newSlideId !== slideId) {
        setSlideId(newSlideId);
        navigate(
          generatePath(URLS.SLIDEVIEWER_HACKROOM_LESSON, {
            slideId: newSlideId,
            studioId,
            viewMode: viewModeToLowerCase(viewMode),
          }),
        );
      }
    },
    [navigate, slideId, studioId, viewMode],
  );

  const sessionId = `${studioId}${lesson?.version || 0}`;

  const onClose = useCallback(() => {
    navigate(generatePath(URLS.HACKROOM));
  }, [navigate]);

  useEffect(() => {
    localStorage.setItem(SLIDE_VIEWER_SIDEBAR_OPEN, false);
  }, []);

  if (slides?.length === 0) {
    return <SlideViewerError onClose={onClose} />;
  }

  return (
    <>
      {slides && (
        <SlideViewer
          generateCurrentLessonPath={({ slideId }) =>
            generateLessonPath({
              isHackRoomLesson: true,
              sessionId,
              slideId,
              studioId,
              viewMode,
            })
          }
          generateLessonPath={({ isHackRoomLesson, slideId, studioId }) =>
            generateLessonPath({
              isHackRoomLesson,
              sessionId,
              slideId,
              studioId,
              viewMode,
            })
          }
          isCodefever={isCodefever}
          isLoadingLesson={isLoadingLesson}
          isLoadingSlides={isLoadingSlides}
          lesson={lesson}
          lessonSessionId={sessionId}
          onChangeSlide={onChangeSlide}
          onChangeViewMode={onChangeViewMode}
          onClose={onClose}
          onSubmitQuestionAnswers={() => {}}
          selectedPeriodIsNotActivePeriod={selectedPeriodIsNotActivePeriod}
          slideId={slideId}
          slides={slides}
          studioId={studioId}
          submittedQuestionAnswers={[]}
          viewMode={viewMode}
        />
      )}
      <div />
    </>
  );
}
