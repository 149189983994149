/* eslint-disable camelcase */
import { InteractionStatus } from '@azure/msal-browser';
import queryString from 'query-string';

import URLS from 'utils/constants/urls';

import { clearCacheAndCookies } from 'utils/clearCacheAndCookies';
import { teamsSubdomains } from '../../utils/constants/teamsSubdomains';

export function handleRedirectAndLocalStorage(account, inProgress, instance) {
  if (
    !account &&
    inProgress === InteractionStatus.None &&
    !window.location.pathname.startsWith(URLS.PRIVACY_POLICY) &&
    !window.location.pathname.startsWith(URLS.TERMS_OF_SERVICE) &&
    !window.location.pathname.startsWith(URLS.LOGIN_FAILED)
  ) {
    // clear local storage the hard way
    clearCacheAndCookies();

    const parsed = queryString.parse(location.search);
    const loginRedirectRequest = {};
    const { session_token, subdomain, target_link_uri } = parsed;
    /*
      If we get the subdomain queryparameter from Smartschool,
      we should add a couple extra queryparam to the login request.
      This will cause the login flow to auto click the smartschool button
      and redirect to the correct smartschool domain so
      the user doesn't need to fill in its subdomain

      We exclude schools that have both smartschool & teams but that ought to login via teams.
    */
    if (subdomain && !teamsSubdomains.includes(subdomain)) {
      loginRedirectRequest.extraQueryParameters = {
        domain_hint: 'smartschool',
        platform: subdomain,
      };
    }

    /*
      If we get the subdomain queryparameter from CodeFever,
      we should add a couple extra queryparam to the login request.
      This will cause the login flow to auto click the invisible codefever button.
    */
    if (session_token && target_link_uri) {
      loginRedirectRequest.extraQueryParameters = {
        domain_hint: 'codefever',
        session_token,
        target_link_uri,
        ui_locales: 'nl',
      };
    }

    instance
      .loginRedirect(loginRedirectRequest)
      .catch(() => instance.handleRedirectPromise());
  }
}
