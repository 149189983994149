import { useMutation } from 'react-query';

import useFormatMessage from 'hooks/useFormatMessage';
import useToast, { TOASTTYPES } from 'hooks/useToast';

import { TEACHER_UPDATE_QUESTION_SCORE } from 'api/portfolioService/mutations/teacherUpdateQuestionScore';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

export const useTeacherUpdateQuestion = () => {
  const toast = useToast();
  const t = useFormatMessage();

  return {
    teacherUpdateQuestionFeedback: useMutation(
      ({ input, lessonSessionId, userId }) =>
        portfolioServiceQueryClient.request(TEACHER_UPDATE_QUESTION_SCORE, {
          input,
          lessonSessionId,
          userId,
        }),
      {
        mutationKey: 'teacherUpdateQuestionFeedback',
        onError: () => {
          toast(TOASTTYPES.UPDATEERROR, {
            str: t('global.FEEDBACK'),
          });
        },
        onSuccess: () => {
          toast(TOASTTYPES.UPDATESUCCESS, {
            str: t('global.FEEDBACK'),
          });
        },
      },
    ),
    teacherUpdateQuestionScore: useMutation(
      ({ input, lessonSessionId, userId }) =>
        portfolioServiceQueryClient.request(TEACHER_UPDATE_QUESTION_SCORE, {
          input,
          lessonSessionId,
          userId,
        }),
      {
        mutationKey: 'teacherUpdateQuestionScore',
        onError: () => {
          toast(TOASTTYPES.UPDATEERROR, {
            str: t('global.SCORE.singular'),
          });
        },
        onSuccess: () => {
          toast(TOASTTYPES.UPDATESUCCESS, {
            str: t('global.SCORE.singular'),
          });
        },
      },
    ),
  };
};
