import 'react-tippy/dist/tippy.css';
import 'tippy.js/dist/tippy.css';
import './fullviewCalendar.css';

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { Eventcalendar, formatDate, locale, Popup } from '@mobiscroll/react';
import { addDays, subDays } from 'date-fns';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useWindowWidth } from '@ftrprf/hooks';
import { ActionStyledAsButton } from '@ftrprf/tailwind-components';

import { CalendarContext } from 'pages/Home/Home';

import { UserContext } from 'providers/UserProvider';

import useTeacherDeleteCalendarItem from 'hooks/api/plannerService/teacher/mutations/useTeacherDeleteCalendarItem';
import useTeacherUpdateCalendarItem from 'hooks/api/plannerService/teacher/mutations/useTeacherUpdateCalendarItem';
import useFormatMessage from 'hooks/useFormatMessage';

import c from 'utils/c';
import { FRIDAY, MONDAY, SATURDAY, SUNDAY } from 'utils/constants/calendarDays';
import {
  END_SCHOOL_EXTENDED,
  END_SCHOOL_NORMAL,
  START_SCHOOL_EXTENDED,
  START_SCHOOL_NORMAL,
} from 'utils/constants/hours';
import URLS from 'utils/constants/urls';
import viewModes from 'utils/constants/viewModes';
import { dataTestIds } from 'utils/dataTestIds';
import { backendAcceptedDateFormat } from 'utils/timeHelpers';
import { LanguageContext } from 'providers/LanguageProvider';
import { errorKeys } from 'utils/constants/errorKeys';
import { useTeamsAuthenticationContext } from 'providers/TeamsLoginProvider';
import { PeriodsContext } from 'providers/PeriodsProvider';
// import Advertisement from 'components/Advertisement';
import { isEventClosed } from './utils/isEventClosed';

import { GroupComposition } from './GroupComposition/GroupComposition';

import CalendarEvent from './partials/CalendarEvent';
import { CustomNavMobile } from './partials/CustomNavMobile';
import EventPopup from './partials/EventPopup';
import { RenderDay } from './partials/RenderDay';
import { getEventTimeInfo } from './utils/getEventTimeInfo';

export default function CalendarMainContainer({
  className = '',
  handleOpenModalCalendarItem,
  selectedDate,
  setEventTimeInfo,
  setFilteredEvents,
  setSelectedDate,
  setSelectedEvent,
  setSelectedEventTitle,
  updateItemOption,
}) {
  const { setShowTeamsAuthenticationDialog } = useTeamsAuthenticationContext();
  const [groupCompositionFormIsOpen, setGroupCompositionFormIsOpen] =
    useState(false);
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const { selectedPeriodIsActivePeriod } = useContext(PeriodsContext);
  const [classGroup, setClassGroup] = useState();
  const [groupRoles, setGroupRoles] = useState();
  const [calendarItemId, setCalendarItemId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [startTime, setStartTime] = useState(START_SCHOOL_NORMAL);
  const [endTime, setEndTime] = useState(END_SCHOOL_NORMAL);
  const [showsExtendedTimes, setShowsExtendedTimes] = useState(
    user?.settings?.calendar?.extendedHours || false,
  );
  const [endDay, setEndDay] = useState(FRIDAY);
  const [showWeekend, setShowWeekend] = useState(
    user?.settings?.calendar?.fullWeek || false,
  );

  const toggleExtendedTimes = () => {
    if (showsExtendedTimes) {
      setStartTime(START_SCHOOL_NORMAL);
      setEndTime(END_SCHOOL_NORMAL);
    } else {
      setStartTime(START_SCHOOL_EXTENDED);
      setEndTime(END_SCHOOL_EXTENDED);
    }

    setShowsExtendedTimes(!showsExtendedTimes);
  };

  const toggleWeekDisplay = () => {
    if (showWeekend) {
      setEndDay(FRIDAY);
    } else {
      setEndDay(SUNDAY);
    }

    setShowWeekend(!showWeekend);
  };

  const timerRef = useRef(null);
  const windowWidth = useWindowWidth();

  const calendarData = useContext(CalendarContext);
  const { events, filteredEvents, selectedClasses, setClasses, setDay } =
    calendarData;

  const t = useFormatMessage();
  const { isTeacher } = useContext(UserContext);
  const { language } = useContext(LanguageContext);
  const { setIsUpdateMode } = updateItemOption;
  const isNotEnglish = language !== 'en';
  const { classGroups, setRange } = calendarData;

  const { error: isErrorUpdateCalendarItem, mutate: updateCalendarItemHook } =
    useTeacherUpdateCalendarItem();

  const {
    error: isErrorDeleteCalendarItem,
    isLoading: isLoadingDeleteCalendarItem,
    mutateAsync: teacherDeleteCalendarItem,
  } = useTeacherDeleteCalendarItem();

  const error = isErrorUpdateCalendarItem || isErrorDeleteCalendarItem;

  const hasNoToken = error?.message.includes(
    errorKeys.TEAMS_ACCESS_TOKEN_NOT_FOUND,
  );

  const updateCalendarItem = async (data) => {
    await updateCalendarItemHook(data);
  };

  const deleteCalendarItem = async (data) => {
    await teacherDeleteCalendarItem(data);

    if (!isErrorDeleteCalendarItem && !isLoadingDeleteCalendarItem) {
      setFilteredEvents(
        filteredEvents.filter((filteredEvent) => filteredEvent.id !== data.id),
      );
      setIsOpen(false);
    }
  };

  const updateItemOpenModal = (event) => {
    setIsUpdateMode(true);
    const { calendarItem, end, start } = event;
    const { studioId } = calendarItem;

    handleOpenModalCalendarItem(studioId);
    setEventTimeInfo(getEventTimeInfo(start, end));
    setSelectedEvent(event);
  };

  const createItemOpenModal = (event) => {
    handleOpenModalCalendarItem();

    const { end, start } = event.event;
    setEventTimeInfo(getEventTimeInfo(start, end));
  };

  const updateItem = (event) => {
    const {
      chapterItemId,
      classGroups,
      closeAfterEndTime,
      end,
      id: eventId,
      start,
      studioId,
      viewMode,
    } = event.event;

    const startTime = backendAcceptedDateFormat(start);
    const endTime = backendAcceptedDateFormat(end);

    const input = {
      startTime,
      endTime,
      chapterItemId,
      closedAfterEndTime: closeAfterEndTime ?? false,
      classGroupIds: classGroups.map((x) => x.id),
      viewMode: viewMode ?? viewModes.CLASSICAL,
      studioId,
    };

    void updateCalendarItem({ id: eventId, input });
  };

  const deleteItem = (event) => {
    void deleteCalendarItem({ id: event.id });
  };

  const renderEventContent = useCallback(
    (calendarData) => <CalendarEvent event={calendarData} />,
    [],
  );

  const getFirstDayOfWeek = useCallback((d, prev) => {
    const foo = new Date(d);
    const day = foo.getDay();
    const diff = foo.getDate() - day + (prev ? -7 : 7);
    return new Date(foo.setDate(diff + 1));
  }, []);

  const getWeekRange = useCallback(
    (d) => {
      const day = d.getDay();
      const diff = d.getDate() - day + 1;
      const begin = new Date(new Date(d).setDate(diff));
      const differentEndDate =
        new Date(d).getDate() - day + (showWeekend ? 7 : 5);
      const end = new Date(new Date(d).setDate(differentEndDate));
      return `${formatDate(
        'DD/MM',
        begin,
        isNotEnglish && {
          dayNames: locale[language].dayNames,
        },
      )} - ${formatDate(
        'DD/MM',
        end,
        isNotEnglish && {
          dayNames: locale[language].dayNames,
        },
      )}`;
    },
    [isNotEnglish, language, showWeekend],
  );

  const navigatePagePerWeek = useCallback(
    (prev) => {
      const prevNextSunday = getFirstDayOfWeek(selectedDate, prev);
      setSelectedDate(prevNextSunday);
      void setDay(prevNextSunday);
    },
    [getFirstDayOfWeek, selectedDate, setDay, setSelectedDate],
  );

  const changeDay = useCallback(
    (prev) => {
      const weekendModifier = showWeekend ? 1 : 3;
      switch (selectedDate.getDay()) {
        case SUNDAY:
          return prev
            ? selectedDate.getDate() - (showWeekend ? 1 : 2)
            : selectedDate.getDate() + 1;
        case MONDAY:
          return prev
            ? selectedDate.getDate() - weekendModifier // go to friday or sunday
            : selectedDate.getDate() + 1;
        case FRIDAY:
          return prev
            ? selectedDate.getDate() - 1
            : selectedDate.getDate() + weekendModifier; // go to monday or saturday
        case SATURDAY:
          return prev
            ? selectedDate.getDate() - 1
            : selectedDate.getDate() + (showWeekend ? 1 : 2); // go to sunday or monday

        default:
          // other days
          // default behaviour of going 1 forward / backward in the calendar
          return prev ? selectedDate.getDate() - 1 : selectedDate.getDate() + 1;
      }
    },
    [selectedDate, showWeekend],
  );

  const navigatePagePerDay = useCallback(
    (prev) => {
      const newDate = new Date(selectedDate);
      newDate.setDate(changeDay(prev));
      void setDay(newDate);
      setSelectedDate(newDate);
    },
    [changeDay, selectedDate, setDay, setSelectedDate],
  );

  useEffect(() => {
    const userExtendedHours = user?.settings?.calendar?.extendedHours || false;
    const userFullWeek = user?.settings?.calendar?.fullWeek || false;

    setShowsExtendedTimes(userExtendedHours);
    setShowWeekend(userFullWeek);

    if (userExtendedHours) {
      setStartTime(START_SCHOOL_EXTENDED);
      setEndTime(END_SCHOOL_EXTENDED);
    }

    if (userFullWeek) {
      setEndDay(SUNDAY);
    }
  }, [
    user?.settings?.calendar?.fullWeek,
    user?.settings?.calendar?.extendedHours,
  ]);

  if (hasNoToken) {
    setShowTeamsAuthenticationDialog(true);
    setIsOpen(false);
  }

  const customNav = () => (
    <>
      <div className="font-bold text-2xl pl-8">
        {getWeekRange(selectedDate)}
      </div>
      {isTeacher && (
        <div className="flex flex-col md:flex-row gap-2">
          {selectedPeriodIsActivePeriod && (
            <ActionStyledAsButton
              iconBefore={PlusIcon}
              onClick={() => handleOpenModalCalendarItem()}
              test={dataTestIds.page.agenda.button.schedule}
            >
              {t('calendar.modal.title')}
            </ActionStyledAsButton>
          )}
          <ActionStyledAsButton
            iconBefore={ClockIcon}
            onClick={toggleExtendedTimes}
            secondary
            test={
              showsExtendedTimes
                ? dataTestIds.page.agenda.button.dayDisplay
                : dataTestIds.page.agenda.button.dayDisplayFull
            }
          >
            {t(
              showsExtendedTimes
                ? 'calendar.modal.extended'
                : 'calendar.modal.normal',
            )}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            iconBefore={CalendarIcon}
            onClick={toggleWeekDisplay}
            secondary
            test={
              showWeekend
                ? dataTestIds.page.agenda.button.weekDisplay
                : dataTestIds.page.agenda.button.weekDisplayFull
            }
          >
            {t(showWeekend ? 'calendar.modal.workweek' : 'calendar.modal.week')}
          </ActionStyledAsButton>
        </div>
      )}
      <div className="flex">
        <ActionStyledAsButton
          className="p-1"
          iconBefore={ChevronLeftIcon}
          iconMargin="p-1"
          onClick={() => navigatePagePerWeek(true)}
          size="small"
          test={dataTestIds.page.agenda.button.previousWeek}
          type="button"
          variant="black"
        >
          <span className="sr-only">{t('global.yesterday')}</span>
        </ActionStyledAsButton>
        <ActionStyledAsButton
          onClick={() => {
            const foo = new Date();
            const day = foo.getDay();
            const diff = foo.getDate() - day;
            const date = new Date(foo.setDate(diff + 1));
            setSelectedDate(date);
            void setDay(date);
          }}
          test={dataTestIds.page.agenda.button.today}
          variant="text"
        >
          {t('global.today')}
        </ActionStyledAsButton>
        <ActionStyledAsButton
          className="p-1 ml-1"
          iconBefore={ChevronRightIcon}
          iconMargin="p-1"
          onClick={() => navigatePagePerWeek(false)}
          size="small"
          test={dataTestIds.page.agenda.button.nextWeek}
          type="button"
          variant="black"
        >
          <span className="sr-only">{t('global.tomorrow')}</span>
        </ActionStyledAsButton>
      </div>
    </>
  );

  const changeView = (event) => {
    setRange(subDays(event.firstDay, 1), addDays(event.lastDay, 1));
  };

  const classButtonClick = (classGroup) => {
    if (selectedClasses.includes(classGroup)) {
      setClasses(selectedClasses?.filter((c) => c.id !== classGroup.id));
    } else {
      setClasses([...selectedClasses, classGroup]);
    }
  };

  const renderDay = useCallback(
    (args) => (
      <RenderDay
        args={args}
        isNotEnglish={isNotEnglish}
        language={language}
        setSelectedDate={setSelectedDate}
        windowWidth={windowWidth}
      />
    ),
    [isNotEnglish, language, setSelectedDate, windowWidth],
  );

  const openTooltip = useCallback((args) => {
    const { event } = args;

    setCurrentEvent(event);
    setCalendarItemId(event.id);
    setGroupRoles(event.groupRoles);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setAnchor(args.domEvent.target);
    setIsOpen(true);
  }, []);

  const openSlideviewer = useCallback(
    (args) => {
      const { event } = args;
      const isClosed = isEventClosed(
        event.calendarItem?.closedAfterEndTime,
        event.end,
      );

      if (isClosed) {
        return;
      }

      setCurrentEvent(event);

      if (event.start <= new Date()) {
        navigate(
          generatePath(URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID, {
            studioId: event.studioId,
            sessionId: event.sessionId,
            viewMode: event.viewMode ?? viewModes.CLASSICAL,
          }),
        );
      }
    },
    [navigate],
  );

  const view = useMemo(
    () => ({
      xsmall: {
        view: {
          schedule: {
            type: 'day',
            startTime,
            endTime,
            startDay: MONDAY,
            endDay,
            allDay: false,
          },
        },
      },
      medium: {
        view: {
          schedule: {
            type: 'week',
            startTime,
            endTime,
            startDay: MONDAY,
            endDay,
            allDay: false,
          },
        },
      },
    }),
    [endDay, endTime, startTime],
  );

  return (
    <>
      {classGroup ? (
        <GroupComposition
          calendarItemId={calendarItemId}
          isOpen={groupCompositionFormIsOpen}
          onDismiss={() => setGroupCompositionFormIsOpen(false)}
          onSubmit={(e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          }}
          options={[
            {
              id: 'RANDOM',
              name: t('groups.compose.option.random'),
            },
            {
              id: 'STEAMS',
              name: t('groups.compose.option.steams'),
            },
          ]}
          possibleRoles={groupRoles}
          sessionId={currentEvent.sessionId}
        />
      ) : null}
      <div className={c(className, 'w-full md:pr-4 pt-4')}>
        {/* <Advertisement /> */}
        <Eventcalendar
          clickToCreate={isTeacher}
          data={isTeacher ? filteredEvents : events}
          dragToCreate={isTeacher}
          dragToMove={isTeacher}
          dragToResize={isTeacher}
          locale={locale[language]}
          onEventClick={isTeacher ? openTooltip : openSlideviewer}
          onEventCreate={createItemOpenModal}
          onEventUpdated={updateItem}
          renderDay={renderDay}
          renderHeader={
            windowWidth > 800
              ? customNav
              : () => (
                  <CustomNavMobile
                    calendarData={calendarData}
                    changeView={changeView}
                    classButtonClick={classButtonClick}
                    getWeekRange={getWeekRange}
                    handleOpenModalCalendarItem={handleOpenModalCalendarItem}
                    navigatePagePerDay={navigatePagePerDay}
                    navigatePagePerWeek={navigatePagePerWeek}
                    selectedDate={selectedDate}
                    selectedPeriodIsActivePeriod={selectedPeriodIsActivePeriod}
                    setSelectedDate={setSelectedDate}
                    showsExtendedTimes={showsExtendedTimes}
                    showWeekend={showWeekend}
                    toggleExtendedTimes={toggleExtendedTimes}
                    toggleWeekDisplay={toggleWeekDisplay}
                  />
                )
          }
          renderScheduleEvent={renderEventContent}
          responsive={view}
          selectedDate={selectedDate}
          showEventTooltip={false}
          stepMinute={10}
          theme="ios"
          themeVariant="light"
          timeFormat="HH:mm"
          touchUi
        />
        {isTeacher && (
          <Popup
            anchor={anchor}
            animation="pop"
            closeOnEsc
            closeOnOverlayClick
            contentPadding={false}
            cssClass="md-tooltip"
            display="anchored"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            showOverlay
            width={350}
          >
            <EventPopup
              event={currentEvent}
              onClose={() => {
                setIsOpen(false);
                setSelectedEventTitle('');
              }}
              onComposeGroups={() => {
                setIsOpen(false);
                setIsUpdateMode(false);
                setGroupCompositionFormIsOpen(true);
                setClassGroup(classGroups[0]);
              }}
              onDelete={() => deleteItem(currentEvent)}
              onEdit={() => {
                setIsOpen(false);
                setIsUpdateMode(true);
                setSelectedEventTitle(currentEvent.title);
                updateItemOpenModal(currentEvent);
              }}
            />
          </Popup>
        )}
      </div>
    </>
  );
}
